import { Language } from './language';

const en = {
    code: Language.en,

	// HOME PAGE
	t_description: '{age} years old drummer girl',
	t_close: 'Close',

	b_about: 'About Me',
	b_press: 'Press',
	b_shows: 'Shows',
	b_language: 'English',
	b_share: 'Share',

	t_english: 'English',
	t_spanish: 'Spanish',
	t_portuguese: 'Portuguese',
	t_italian: 'Italian',
	t_french: 'French',
	t_german: 'German',
	t_chinese: 'Chinese',

	// SHOWS PAGE

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Ballard Seafood Fest 2024',
	BallardSeaFoodFest2024_description: 'Ballard Seafood Fest',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Modern Metal',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Season Preview Winter 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Hair Metal',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: '2023 Talent Show',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Season Preview Spring 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'The Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Soundtrack Show',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: '90\'s Nostalgia',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: '2022 Talent Show',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',


	// PRESS PAGE

	alizee_defan_07_02_2024_title: '"Alizee Defan" Interview',
	alizee_defan_07_02_2024_description: 'Exclusive interview',

	jaime_mendez_news_06_26_2024_title: '"Jaime Mendez News" Interview',
	jaime_mendez_news_06_26_2024_description: 'Local Seattle News in spanish',
	
	bien_informado_03_22_2023_title: '"Bien Informado" Article',
	bien_informado_03_22_2023_description: 'Argentina News Website Article about Victoria Drums',

	wave_potter_reaction_01_30_2023_title: 'Wave Potter Reaction',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reacts to Victoria\'s cover of Lux Æterna by Metallica',

	// ABOUT PAGE

	b_back: 'Go Back',
	
	t_title: 'Victoria Acosta',
	t_pronouns: '(she/her)',

	t_about: `
	Victoria is a {age} year old drummer born in El Paso, Texas, in the United States. She comes from a Mexican family originally from Ciudad Juarez, Mexico.

	Victoria lived her first six years in Guadalajara, Mexico. She began showing interest in drums from a very early age, expressing to her parents her desire to play the drums or stating that her favorite instrument was the drums. Initially, her parents dismissed it as a passing phase, but her passion never waned.
	
	In September 13th 2018, Victoria received her first drum kit, a children's set, and immediately began practicing on it. A couple of weeks later, her parents found a music school and wanted to enroll her. Although the minimum age for enrollment was six and Victoria was only four, she was given a trial lesson. If she demonstrated skills and could pay attention for the whole hour, she could stay, and that's how she began her musical education at the "Musicalerias" Academy.
	
	In October 10th 2019, Victoria and her family moved to Seattle, Washington, in the United States, starting a new chapter in their lives. She joined a new school and continued her drumming lessons with her Mexican academy but online.
	
	In June 2022, Victoria attempted to enroll in the School of Rock. She was very young for the Performers Program; typically, children her age start in a Rock 101 Program for beginners. However, after trial lessons, she was allowed to join the performers, already having completed {shows} shows.
	
	Victoria continues to learn and practice diligently every day. She has recorded dozens of covers and shared them on her social media platforms. Additionally, she has participated in several contests, winning the "Gold Prize" and "Fantastic Technique" at the "London Young Musician Contest 2022." She also received the "Annual Star Musician Award" and the "Annual Outstanding Drummer Special Award" at "The London Young Musician of the Year 2023."
	
	Victoria is aware that her musical journey is just beginning, and she remains focused on learning, practicing hard, and above all, enjoying the process every day.
	`
}

export default en;
