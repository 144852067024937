import { Language } from './language';

const zh = {
    code: Language.zh,

	// HOME PAGE
	
	t_description: '女鼓手，{age}岁',
	t_close: '关闭',
	
	b_about: '关于我',
	b_press: '按',
	b_shows: '演出',
	b_language: '中国人',
	b_share: '分享',

	t_english: '英语',
	t_spanish: '西班牙语',
	t_portuguese: '葡萄牙语',
	t_italian: '意大利语',
	t_french: '法语',
	t_german: '德语',
	t_chinese: '中国人',

	// SHOWS PAGE

	BluesRock_title: '蓝调摇滚',
	BluesRock_description: '西雅图摇滚学校',

	BallardSeaFoodFest2024_title: '二零二四 巴拉德海鲜节',
	BallardSeaFoodFest2024_description: '巴拉德海鲜节',

	Funk_title: '放克',
	Funk_description: '西雅图摇滚学校',

	Heart_title: '心',
	Heart_description: '西雅图摇滚学校',

	ModernMetal_title: '现代金属',
	ModernMetal_description: '西雅图摇滚学校',

	SeasonPreviewWinter2023_title: '二零二三 年冬季季节预览',
	SeasonPreviewWinter2023_description: '西雅图摇滚学校',

	SmashingPumpkins_title: '粉碎南瓜',
	SmashingPumpkins_description: '西雅图摇滚学校',

	Nirvana_title: '涅槃',
	Nirvana_description: '西雅图摇滚学校',

	HairMetal_title: '头发金属',
	HairMetal_description: '西雅图摇滚学校',

	TalentShow2023_title: '二零二三才艺展示',
	TalentShow2023_description: '西雅图鲑鱼湾小学',

	Deftones_title: '去色调',
	Deftones_description: '西雅图摇滚学校',

	SeasonPreviewSpring2023_title: '二零二三 年春季季前瞻',
	SeasonPreviewSpring2023_description: '西雅图摇滚学校',

	Ramones_title: '雷蒙斯',
	Ramones_description: '西雅图摇滚学校',

	Soundtrack_title: '配乐秀',
	Soundtrack_description: '西雅图摇滚学校',

	PopPunkVsEmo_title: '流行朋克与。 情绪',
	PopPunkVsEmo_description: '西雅图摇滚学校',

	_90sNostalgia_title: '九零年代怀旧',
	_90sNostalgia_description: '西雅图摇滚学校',

	TalentShow2022_title: '二零二二才艺展示',
	TalentShow2022_description: '西雅图鲑鱼湾小学',

	// PRESS PAGE

	alizee_defan_07_02_2024_title: '《艾莉婕·德凡》专访',
	alizee_defan_07_02_2024_description: '独家专访',

	jaime_mendez_news_06_26_2024_title: '《海梅·门德斯新闻》采访',
	jaime_mendez_news_06_26_2024_description: '西雅图当地西班牙语新闻',

	bien_informado_03_22_2023_title: '“消息灵通" 文章',
	bien_informado_03_22_2023_description: '阿根廷新闻网站关于维多利亚鼓的文章',

	wave_potter_reaction_01_30_2023_title: '波波特 反应',
	wave_potter_reaction_01_30_2023_description: '波波特 对 胜利 的 永恒之光 封面做出反应',

	// ABOUT PAGE

	b_back: '回去',

	t_title: 'Victoria Acosta',
	t_pronouns: '她',

	t_about: `
	胜利 是一名 {age} 岁的鼓手，出生于美国得克萨斯州埃尔帕索。 她来自墨西哥华雷斯城的一个墨西哥家庭。

	维多利亚在墨西哥瓜达拉哈拉度过了她的头六年，她从很小的时候就开始对鼓产生兴趣，并开始对父母说她想打鼓或者她最喜欢的乐器是鼓。 他们过去常常无视她，认为这是暂时的，但她从未停止过。

	二零一八年九月，V胜利收到了她的第一套架子鼓，是一套儿童架子鼓，她马上就跳过了，几周后，她的父母找到了一所音乐学校，想让她入学，最低入学年龄是六岁，维多利亚只有四岁，但得到了试听课，如果她表现出一些技巧并且可以在课堂上全神贯注，她就可以留下来，这就是她在“Musicalerias”学院开始音乐教育的方式。

	二零一九年一零月，胜利和家人搬到了美国华盛顿西雅图，开始了新的生活。 她加入了一所新学校，并继续她在墨西哥学院的击鼓课程，但是是在线的。

	二零二二 年 六 月，胜利 尝试注册 摇滚学院，对于 表演者 程序 岩石 一零一 程序 对于初学者 开始的，所以她得到了试听课，并且可以和已经有表演经验的表演者呆在一起到目前为止有{shows}场演出。

	胜利 每天都在不断地学习和练习，她已经录制了数十个翻唱并在她的社交媒体上分享。 此外，她还参加过多项比赛。 在“二零二二年伦敦青年音乐家大赛”中获得“金奖”和“神奇技巧”，并在“二零二三年伦敦青年音乐家”中获得“年度明星音乐家奖”和“年度杰出鼓手特别奖” ”。

	维多利亚意识到她的音乐之旅才刚刚开始，她非常专注于学习和努力练习，但最重要的是每天都享受这个过程。
	`
}

export default zh;
