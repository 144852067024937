import { Language } from './language';

const es = {
    code: Language.es,

	// HOME PAGE

	t_description: 'Niña baterista, {age} años de edad',
	t_close: 'Cerrar',

	b_about: 'Acerca de mi',
	b_press: 'Prensa',
	b_shows: 'Shows',
	b_language: 'Español',
	b_share: 'Compartir',

	t_english: 'Inglés',
	t_spanish: 'Español',
	t_portuguese: 'Portugués',
	t_italian: 'Italiano',
	t_french: 'Francés',
	t_german: 'Alemán',
	t_chinese: 'Chino',

	// SHOWS PAGE

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival de comida del mar en Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival de comida del mar en Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Metal Moderno',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Concierto de avance de invierno 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Hair Metal',
	HairMetal_description: 'School of Rock Seattle',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Show de Talentos 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	SeasonPreviewSpring2023_title: 'Concierto de avance de primavera 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'Los Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Show de Soundtracks',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgia de los 90\'s',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Show de Talentos 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	alizee_defan_07_02_2024_title: 'Entrevista con "Alizee Defan"',
	alizee_defan_07_02_2024_description: 'Entrevista exclusiva',

	jaime_mendez_news_06_26_2024_title: 'Entrevista con "Jaime Mendez News"',
	jaime_mendez_news_06_26_2024_description: 'Noticiero local de Seattle en español',

	bien_informado_03_22_2023_title: 'Artículo de "Bien Informado"',
	bien_informado_03_22_2023_description: 'Artículo de Sitio de Noticias Argentino acerca de Victoria',

	wave_potter_reaction_01_30_2023_title: 'Reacción de Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reacciona al cover de Victoria Lux Æterna de Metallica',
	
	// ABOUT PAGE

	b_back: 'Regresar',

	t_title: 'Victoria Acosta',
	t_pronouns: '(ella)',

	t_about: `
	Victoria es una niña de {age} años nacida en El Paso, Texas, Estados Unidos, y proviene de una familia mexicana de Ciudad Juárez, México.

	Vivió sus primeros seis años en Guadalajara, México, donde comenzó a mostrar interés por la música, específicamente por la batería, desde muy temprana edad. Expresaba a sus padres su deseo de tocar la batería o afirmaba que era su instrumento favorito. Al principio, sus padres pensaban que era una fase pasajera, pero su pasión nunca disminuyó.

	El 13 septiembre del 2018, recibió su primera batería, era una versión para niños, y comenzó a practicar de inmediato. Un par de semanas después, sus padres encontraron una escuela de música y decidieron inscribirla. A pesar de que la edad mínima para ingresar era de seis años y Victoria solo tenía cuatro, le dieron una clase de prueba. Si demostraba habilidades y podía prestar atención durante toda la hora, podría quedarse, y así comenzó su educación musical en la Academia "Musicalerías".

	El 10 octubre de 2019, Victoria y su familia se mudaron a Seattle, Washington, Estados Unidos, donde comenzaron una nueva vida. Se unió a una nueva escuela y continuó sus lecciones de música con su academia mexicana, pero de forma virtual.

	En junio de 2022, Victoria intentó inscribirse en la "School of Rock". Aunque era muy joven para el Programa de "Performers", típicamente destinado a niños mayores, después de clases de prueba, se le permitió unirse a "Performers", habiendo completado ya {shows} shows.

	Victoria sigue aprendiendo y practicando diligentemente todos los días. Ha grabado docenas de covers y los ha compartido en sus redes sociales. Además, ha participado en varios concursos, ganando el "Premio de Oro" y el premio especial a la "Técnica Fantástica" en el "London Young Musician Contest 2022". También recibió el "Annual Star Musician Award" y el "Annual Outstanding Drummer Special Award" en "London Young Musician Contest 2022".

	Victoria es consciente de que su viaje musical apenas comienza y sigue enfocada en aprender, practicar duro y, sobre todo, disfrutar del proceso cada día.
	`
};

export default es;