import { Language } from './language';

const it = {
    code: Language.it,

	// HOME PAGE

	t_description: 'Batterista ragazza, {age} anni',
	t_close: 'Vicino',

	b_about: 'Su di me',
	b_press: 'Premere',
	b_shows: 'Spettacoli',
	b_language: 'Italiano',
	b_share: 'Condividere',

	t_english: 'Inglese',
	t_spanish: 'Spagnolo',
	t_portuguese: 'Portoghese',
	t_italian: 'Italiano',
	t_french: 'Francese',
	t_german: 'Tedesco',
	t_chinese: 'Cinese',

	// SHOWS PAGE

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival dei frutti di mare Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival dei frutti di mare Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',
	
	ModernMetal_title: 'Metallo moderno',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Anteprima stagione inverno 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Metallo per capelli',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Talent Show 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Anteprima di stagione primavera 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'I Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Spettacolo di colonne sonore',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgia anni \'90',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Talent Show 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	alizee_defan_07_02_2024_title: 'Intervista con "Alizee Defan"',
	alizee_defan_07_02_2024_description: 'Intervista esclusiva',

	jaime_mendez_news_06_26_2024_title: 'Intervista a "Jaime Mendez News"',
	jaime_mendez_news_06_26_2024_description: 'Notizie locali di Seattle in spagnolo',

	bien_informado_03_22_2023_title: 'Articolo di "Bien Informado"',
	bien_informado_03_22_2023_description: 'Articolo del sito di notizie argentino su Victoria',

	wave_potter_reaction_01_30_2023_title: 'La reazione di Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reagisce alla copertina di Victoria Lux Æterna dei Metallica',
	
	// ABOUT PAGE

	b_back: 'Torna indietro',

	t_title: 'Victoria Acosta',
	t_pronouns: '(lei)',

	t_about: `
	Victoria è una bambina di {age} anni nata a El Paso, Texas, negli Stati Uniti, e proviene da una famiglia messicana di Ciudad Juárez, in Messico.

	Ha trascorso i suoi primi sei anni a Guadalajara, Messico, dove ha iniziato a mostrare interesse per la musica, specificamente per la batteria, fin da tenera età. Esprimeva ai suoi genitori il desiderio di suonare la batteria o affermava che fosse il suo strumento preferito. Inizialmente, i suoi genitori pensavano che fosse solo una fase passeggera, ma la sua passione non è mai diminuita.

  Il 13 settembre 2018, ha ricevuto la sua prima batteria, una versione per bambini, e ha iniziato subito a esercitarsi. Un paio di settimane dopo, i suoi genitori hanno trovato una scuola di musica e hanno deciso di iscriverla. Nonostante l'età minima per l'iscrizione fosse di sei anni e Victoria ne avesse solo quattro, le è stata data una lezione di prova. Se dimostrava competenze e riusciva a rimanere concentrata per tutta l'ora, poteva restare, e così ha iniziato la sua educazione musicale presso l'Accademia "Musicalerías".

	Il 10 ottobre 2019, Victoria e la sua famiglia si sono trasferite a Seattle, Washington, negli Stati Uniti, dove hanno iniziato una nuova vita. Si è unita a una nuova scuola e ha continuato le sue lezioni di musica con la sua accademia messicana, ma online.

	Nel giugno 2022, Victoria ha cercato di iscriversi alla "School of Rock". Anche se era molto giovane per il Programma "Performers", tipicamente destinato a bambini più grandi, dopo le lezioni di prova le è stato permesso di unirsi ai "Performers", avendo già completato {shows} spettacoli.

	Victoria continua a imparare e a esercitarsi diligentemente ogni giorno. Ha registrato dozzine di cover e le ha condivise sui suoi social media. Inoltre, ha partecipato a vari concorsi, vincendo il "Premio Oro" e il premio speciale per la "Fantastica Tecnica" al "London Young Musician Contest 2022". Ha anche ricevuto il "Premio Annuale al Musicista Star" e il "Premio Annuale Speciale al Batterista Eccezionale" al "London Young Musician Contest 2022".

	Victoria è consapevole che il suo viaggio musicale è appena iniziato e rimane concentrata nell'apprendere, esercitarsi duramente e, soprattutto, nel godersi il processo ogni giorno.
	`
};

export default it;
