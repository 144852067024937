import { Language } from './language';

const fr = {
    code: Language.fr,

	// HOME PAGE
	
	t_description: 'Fille batteuse de {age} ans',
	t_close: 'Fermer',

	b_about: 'Sur moi',
	b_press: 'Presse',
	b_shows: 'Montre',
	b_language: 'Français',
	b_share: 'Partager',

	t_english: 'Anglaise',
	t_spanish: 'Espagnole',
	t_portuguese: 'Portugais',
	t_italian: 'Italien',
	t_french: 'Français',
	t_german: 'Allemand',
	t_chinese: 'Chinois',

	// SHOWS PAGE

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival des fruits de mer Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival des fruits de mer Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',
	
	ModernMetal_title: 'Métal moderne',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Aperçu de la saison hiver 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Cheveux Métal',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Spectacle de talents 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Aperçu de la saison printemps 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'Les Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Spectacle de la bande originale',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgie des années 90',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Spectacle de talents 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	alizee_defan_07_02_2024_title: 'Entretien avec "Alizée Defan"',
	alizee_defan_07_02_2024_description: 'Interview exclusive',

	jaime_mendez_news_06_26_2024_title: 'Interview "Jaime Mendez Nouvelles"',
	jaime_mendez_news_06_26_2024_description: 'Nouvelles locales de Seattle en espagnol',

	bien_informado_03_22_2023_title: 'Article de "Bien Informado"',
	bien_informado_03_22_2023_description: 'Article du site d\'information argentin sur Victoria',

	wave_potter_reaction_01_30_2023_title: 'La réaction de Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter réagit à la reprise de Victoria Lux Æterna de Metallica',

	// ABOUT PAGE

	b_back: 'Retourner',

	t_title: 'Victoria Acosta',
	t_pronouns: '(elle)',

	t_about: `
	Victoria est une batteuse de {age} ans née à El Paso, au Texas, aux États-Unis. Elle vient d'une famille mexicaine originaire de Ciudad Juarez, au Mexique.

	Victoria a vécu ses six premières années à Guadalajara, au Mexique. Elle a commencé à montrer de l'intérêt pour la batterie dès son plus jeune âge, exprimant à ses parents son désir de jouer de la batterie ou affirmant que son instrument préféré était la batterie. Initialement, ses parents ont considéré cela comme une phase passagère, mais sa passion n'a jamais diminué.

	Le 13 septembre 2018, Victoria a reçu son premier kit de batterie, un ensemble pour enfants, et a immédiatement commencé à s'entraîner dessus. Quelques semaines plus tard, ses parents ont trouvé une école de musique et ont voulu l'inscrire. Bien que l'âge minimum pour l'inscription soit de six ans et que Victoria n'ait que quatre ans, elle a eu une leçon d'essai. Si elle démontrait des compétences et pouvait rester attentive pendant toute l'heure, elle pouvait rester, et c'est ainsi qu'elle a commencé son éducation musicale à l'Académie "Musicalerias".

	Le 10 octobre 2019, Victoria et sa famille ont déménagé à Seattle, Washington, aux États-Unis, ouvrant un nouveau chapitre dans leur vie. Elle a rejoint une nouvelle école et a continué ses leçons de batterie avec son académie mexicaine mais en ligne.

	En juin 2022, Victoria a tenté de s'inscrire à l'École de Rock. Elle était très jeune pour le Programme de Performers; généralement, les enfants de son âge commencent par un programme Rock 101 pour débutants. Cependant, après des leçons d'essai, elle a été autorisée à rejoindre les interprètes, ayant déjà réalisé {shows} spectacles.

	Victoria continue d'apprendre et de s'entraîner assidûment chaque jour. Elle a enregistré des dizaines de reprises et les a partagées sur ses plateformes de médias sociaux. De plus, elle a participé à plusieurs concours, remportant le "Prix d'Or" et la "Technique Fantastique" lors du "Concours de Jeunes Musiciens de Londres 2022". Elle a également reçu le "Prix Annuel du Musicien Étoile" et le "Prix Spécial Annuel du Batteur Exceptionnel" lors de "The London Young Musician of the Year 2023".

	Victoria est consciente que son voyage musical ne fait que commencer, et elle reste concentrée sur l'apprentissage, la pratique assidue et surtout, le plaisir du processus chaque jour.
	`
}

export default fr;
