import { Language } from './language';

const de = {
    code: Language.de,

	// HOME PAGE
	
	t_description: '{age} Jahre altes Schlagzeugermädchen',
	t_close: 'Schließen',

	b_about: 'Über mich',
	b_press: 'Drücken Sie',
	b_shows: 'Zeigt an',
	b_language: 'Deutsch',
	b_share: 'Aktie',

	t_english: 'Englisch',
	t_spanish: 'Spanisch',
	t_portuguese: 'Portugiesisch',
	t_italian: 'Italienisch',
	t_french: 'Französisch',
	t_german: 'Deutsch',
	t_chinese: 'Chinesisch',

	// SHOWS PAGE

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Ballard Seafood Fest 2024',
	BallardSeaFoodFest2024_description: 'Ballard Seafood Fest',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Moderner Metal',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Saisonvorschau Winter 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Haarmetall',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Talentshow 2023',
	TalentShow2023_description: 'Grundschule Seattle Salmon Bay',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Saisonvorschau Frühjahr 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'The Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Soundtrack Show',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgie der 90er Jahre',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Talentshow 2022',
	TalentShow2022_description: 'Grundschule Seattle Salmon Bay',
	
	// PRESS PAGE

	alizee_defan_07_02_2024_title: '"Alizee Defan" interview',
	alizee_defan_07_02_2024_description: 'Eksklusivt interview',

	jaime_mendez_news_06_26_2024_title: '"Jaime Mendez Nieuws"-interview',
	jaime_mendez_news_06_26_2024_description: 'Lokaal Seattle-nieuws in het Spaans',

	bien_informado_03_22_2023_title: 'Artikel von "Bien Informado',
	bien_informado_03_22_2023_description: 'Artikel auf der argentinischen Nachrichtenseite über Victoria Drums',

	wave_potter_reaction_01_30_2023_title: 'Wave Potter Reaktion',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reagiert auf Victorias Cover von Lux Æterna von Metallica',

	// ABOUT PAGE

	b_back: 'Geh zurück',

	t_title: 'Victoria Acosta',
	t_pronouns: '(sie/ihr)',

	t_about: `
	Victoria ist eine {age} jährige Schlagzeugerin, geboren in El Paso, Texas, in den Vereinigten Staaten. Sie stammt aus einer mexikanischen Familie, die ursprünglich aus Ciudad Juarez, Mexiko, stammt.

	Victoria verbrachte ihre ersten sechs Jahre in Guadalajara, Mexiko. Schon in sehr jungen Jahren zeigte sie Interesse am Schlagzeug, indem sie ihren Eltern ihren Wunsch mitteilte, Schlagzeug zu spielen, oder angab, dass ihr Lieblingsinstrument das Schlagzeug sei. Anfangs sahen ihre Eltern das als vorübergehende Phase an, aber ihre Leidenschaft ließ nie nach.

	Op 13 september 2018 erhielt Victoria ihr erstes Schlagzeug-Set, ein Kinder-Set, und begann sofort damit zu üben. Ein paar Wochen später fanden ihre Eltern eine Musikschule und wollten sie einschreiben. Obwohl das Mindestalter für die Einschreibung sechs Jahre betrug und Victoria erst vier war, erhielt sie eine Probelektion. Wenn sie Fähigkeiten zeigte und eine Stunde lang aufmerksam bleiben konnte, durfte sie bleiben, und so begann sie ihre musikalische Ausbildung an der "Musicalerias"-Akademie.

	Op 10 Oktober 2019 zog Victoria mit ihrer Familie nach Seattle, Washington, in den Vereinigten Staaten, und begann ein neues Kapitel in ihrem Leben. Sie besuchte eine neue Schule und setzte ihre Schlagzeugstunden mit ihrer mexikanischen Akademie online fort.

	Im Juni 2022 versuchte Victoria, sich an der School of Rock einzuschreiben. Sie war sehr jung für das Performers-Programm; normalerweise beginnen Kinder in ihrem Alter im Rock 101 Programm für Anfänger. Nach Probelektionen durfte sie jedoch bei den Performern mitmachen und hatte bereits {shows} Auftritte absolviert.

	Victoria lernt weiterhin fleißig und übt jeden Tag. Sie hat Dutzende von Covers aufgenommen und auf ihren Social-Media-Plattformen geteilt. Außerdem hat sie an mehreren Wettbewerben teilgenommen und den "Goldpreis" und die "Fantastische Technik" beim "London Young Musician Contest 2022" gewonnen. Sie erhielt auch den "Jährlichen Star-Musiker-Preis" und den "Jährlichen Herausragenden Schlagzeuger-Sonderpreis" bei "The London Young Musician of the Year 2023".

	Victoria ist sich bewusst, dass ihre musikalische Reise gerade erst begonnen hat, und sie bleibt konzentriert darauf, zu lernen, hart zu üben und vor allem den Prozess jeden Tag zu genießen.
	`
}

export default de;
