import { Language } from './language';

const pt = {
    code: Language.pt,

	// HOME PAGE

	t_description: 'Menina baterista, {age} anos',
	t_close: 'Fechar',
	
	b_about: 'Sobre mim',
	b_press: 'Imprensa',
	b_shows: 'Shows',
	b_language: 'Português',
	b_share: 'Compartilhar',

	t_english: 'Inglês',
	t_spanish: 'Espanhol',
	t_portuguese: 'Português',
	t_italian: 'Italiano',
	t_french :'Francês',
	t_german: 'Alemão',
	t_chinese: 'Chinês',

	// SHOWS PAGE

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival de Frutos do Mar Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival de Frutos do Mar Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Metal Moderno',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Prévia da Temporada Inverno 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Hair Metal',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Show de Talentos 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Prévia da Temporada Primavera de 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'Os Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Show da Trilha Sonora',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgia dos anos 90',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Show de Talentos 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	alizee_defan_07_02_2024_title: 'Entrevista com "Alizee Defan"',
	alizee_defan_07_02_2024_description: 'Entrevista exclusiva',

	jaime_mendez_news_06_26_2024_title: 'Entrevista "Jaime Mendez News"',
	jaime_mendez_news_06_26_2024_description: 'Notícias locais de Seattle em espanhol',

	bien_informado_03_22_2023_title: 'Artigo de "Bien Informado"',
	bien_informado_03_22_2023_description: 'Artigo do site de notícias argentino sobre Victoria',

	wave_potter_reaction_01_30_2023_title: 'A reação de Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reage ao cover de Victoria Lux Æterna do Metallica',
	
	// ABOUT PAGE

	b_back: 'Volte',

	t_title: 'Victoria Acosta',
	t_pronouns: '(ela)',
	
	t_about: `
	Victoria é uma menina de {age} anos nascida em El Paso, Texas, nos Estados Unidos, e vem de uma família mexicana originalmente de Ciudad Juarez, México.

	Viveu seus primeiros seis anos em Guadalajara, México. Começou a demonstrar interesse pela música, especificamente pela bateria, desde muito jovem, expressando aos seus pais o desejo de tocar bateria ou afirmando que era o seu instrumento favorito. Inicialmente, seus pais consideraram isso como uma fase passageira, mas sua paixão nunca diminuiu.

	Em 13 de setembro de 2018, recebeu seu primeiro kit de bateria, uma versão para crianças, e começou a praticar imediatamente. Algumas semanas depois, seus pais encontraram uma escola de música e quiseram inscrevê-la. Embora a idade mínima para a inscrição fosse de seis anos e Victoria tivesse apenas quatro, ela recebeu uma aula experimental. Se demonstrasse habilidades e conseguisse prestar atenção durante toda a hora, poderia ficar, e assim começou sua educação musical na Academia "Musicalerías".

	Em 10 de outubro de 2019, Victoria e sua família mudaram-se para Seattle, Washington, nos Estados Unidos, começando um novo capítulo em suas vidas. Ela ingressou em uma nova escola e continuou suas lições de bateria com sua academia mexicana, mas online.

	Em junho de 2022, Victoria tentou se inscrever na "School of Rock". Embora fosse muito jovem para o Programa de "Performers", geralmente destinado a crianças mais velhas, após aulas experimentais, foi permitido que se juntasse aos "Performers", já tendo completado {shows} shows.

	Victoria continua aprendendo e praticando diligentemente todos os dias. Ela gravou dezenas de covers e os compartilhou em suas redes sociais. Além disso, ela participou de vários concursos, ganhando o "Prêmio Ouro" e o prêmio especial de "Técnica Fantástica" no "London Young Musician Contest 2022". Ela também recebeu o "Prêmio Anual de Músico Estrela" e o "Prêmio Anual Especial de Baterista Destaque" no "London Young Musician Contest 2022".

	Victoria está ciente de que sua jornada musical está apenas começando e continua focada em aprender, praticar duro e, acima de tudo, aproveitar o processo todos os dias.
	`
};

export default pt;
